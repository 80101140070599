import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"
import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useStore } from "react-redux"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { providerImage } from "../utils"
import * as styles from "./style.module.scss"

const ProvidersListView = props => {
  const store = useStore()

  const [selectedProviders, setSelectedProviders] = useState([])
  const [open, setOpen] = useState(false)
  const [majorOpen, setMajorOpen] = useState(false)
  const [challengerOpen, setChallengerOpen] = useState(false)

  const { selectedProvider, didSelectProviderCallback, didSelectProviders, isSelectYourBanks, otherBanks } = props
  const isItemSelectedMode = selectedProvider !== null && !isSelectYourBanks

  //console.log("props.list:", props.list)
  const mainItems = props.list.filter(x => x.big4 === true && (x.inSurvey === true || isSelectYourBanks)).sort((a, b) => (a.id > b.id ? 1 : -1))
  // //console.log("mainItems:", mainItems)
  let otherItems = props.list.filter(x => x.big4 === false && (x.inSurvey === true || isSelectYourBanks))
  if (isSelectYourBanks) {
    otherItems = otherItems.concat(otherBanks)
  }
  otherItems = otherItems.sort((a, b) => (b.id > 0 && a.id > b.id ? 1 : -1))
  // console.log("otherItems:", otherItems)

  if (isSelectYourBanks && selectedProvider && !selectedProviders.some(p => p.id === selectedProvider)) {
    props.list.map(p => {
      //console.log(p)
      if (p.id === selectedProvider) {
        selectedProviders.push(p)
      }
    })
  }

  const listItem = (obj, i) => {
    return (
      <Clickable.View
        key={i}
        rootStyle={
          isItemSelectedMode
            ? {
                height: "50px",
                borderBottom: "0.5px solid #eeeeee",
                alignItems: "center",
                display: "flex",
              }
            : {
                height: "60px",
                width: "100%",
                borderBottom: "0.5px solid #eeeeee",
              }
        }
        disabled={isItemSelectedMode || (isSelectYourBanks && obj.id === selectedProvider)}
        onClick={() => {
          if (isItemSelectedMode) return
          if (obj.id === "00000") {
            navigate("/credit-cards/select-card-provider/add-missing-provider", {
              state: {
                cardProvider: obj,
                progress: 0,
              },
            })
            return
          }
          if (isSelectYourBanks) {
            setSelectedProviders(oldArray => {
              if (obj.id === selectedProvider) {
                return [...oldArray]
              }
              if (oldArray.filter(item => item.id === obj.id).length > 0) {
                const newArray = oldArray.filter(item => item.id !== obj.id)
                return newArray
              } else {
                if (selectedProviders.length > 2) {
                  return [...oldArray]
                }
                const newArray = [...oldArray, obj]
                return newArray
              }
            })
          } else {
            didSelectProviderCallback(obj)
          }
        }}
      >
        {/* https://stackoverflow.com/questions/54332350/how-to-make-a-circle-checkbox-with-material-ui/54332642 */}
        {isSelectYourBanks ? <img src={selectedProviders.filter(item => item.id === obj.id).length > 0 ? "/images/checkbox_selected_true.png" : "/images/checkbox_selected_false.png"} style={{ width: "24px", height: "24px", marginLeft: "5px" }} alt="" /> : null}
        <LazyLoadImage src={providerImage(obj.id)} alt="" style={{ width: "56px", height: "38px", marginLeft: isSelectYourBanks ? "26px" : "0px" }} />
        <div className={styles.listItemText}>
          <Typography.H6 comp="list-item">{obj.name}</Typography.H6>
        </div>
      </Clickable.View>
    )
  }

  return isItemSelectedMode ? (
    listItem(selectedProvider)
  ) : isSelectYourBanks ? (
    <div>
      {listItem(mainItems.find(x => x.id === selectedProvider) || otherItems.find(x => x.id === selectedProvider))}
      <div
        className={styles.listSubHeader2}
        onClick={() => {
          DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_BANKS_MAJORS_SELECTED, { ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
          setMajorOpen(!majorOpen)
        }}
        role="button"
        tabIndex={0}
      >
        <div className={styles.listSubHeaderText}>Majors</div>
        {majorOpen ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Collapse in={majorOpen} timeout="auto" unmountOnExit>
        {mainItems.filter(x => x.id !== selectedProvider).map((obj, i) => listItem(obj, i))}
      </Collapse>

      <div
        className={styles.listSubHeader2}
        onClick={() => {
          DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_BANKS_CHALLENGERS_SELECTED, { ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
          setChallengerOpen(!challengerOpen)
        }}
        role="button"
        tabIndex={0}
      >
        <div className={styles.listSubHeaderText}>Challengers</div>
        {challengerOpen ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Collapse in={challengerOpen} timeout="auto" unmountOnExit>
        {otherItems.filter(x => x.id !== selectedProvider).map((obj, i) => listItem(obj, i))}
      </Collapse>

      <Clickable.Text
        comp={3}
        className="survey-button"
        onClick={() => {
          didSelectProviders(selectedProviders)
          store.offerOpenIndex = null
        }}
      >
        See better deals
      </Clickable.Text>
    </div>
  ) : (
    <div>
      <div className={styles.listSubHeader}>
        <Typography.H6 comp="list-sub-heading">Majors</Typography.H6>
      </div>
      {mainItems.map((obj, i) => listItem(obj, i))}
      <div className={styles.listSubHeader}>
        <Typography.H6 comp="list-sub-heading">Other</Typography.H6>
      </div>
      {otherItems.map((obj, i) => listItem(obj, i))}
    </div>
  )
}

export default ProvidersListView
