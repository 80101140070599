import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"
import * as Clickable from "components/clickable"
import Disclaimer from "components/survey/disclaimer"
import ProductView from "components/survey/product-view"
import { providerImage } from "components/survey/utils"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import AppManager from "services/app-manager"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import * as styles from "./style.module.scss"

const OffersView = props => {
  const reactStore = useStore()
  const [openIndex, setOpenIndex] = useState(reactStore.offerOpenIndex !== null ? reactStore.offerOpenIndex : -1)
  const [filterIndex, setFilterIndex] = useState(props.location.state ? props.location.state.filterIndex : 1)
  const [openFilter, setOpenFilter] = useState(filterIndex > -1 ? true : false)

  const jsonResponse = props.location.state.responseJson
  const jsonInput = props.location.state.jsonInput
  const isQA = props.isQA ? props.isQA : false
  const unsuccessfulNudge = props.unsuccessfulNudge ? props.unsuccessfulNudge : false
  //console.log("jsonResponse:", jsonResponse)

  // console.log("update state", openFilter)

  // deep copy offer groups
  const offerGroups = JSON.parse(JSON.stringify(jsonResponse.offerGroups))

  for (let i = 0; i < offerGroups.length; i++) {
    offerGroups[i].offerPage1 = offerGroups[i].offerPage1.filter(x => (filterIndex === 0 ? x.benefitsSavings > 0 : filterIndex === 2 ? x.benefitsRewards > 0 : true))
    if (offerGroups[i].offerPage2) {
      offerGroups[i].offerPage2 = offerGroups[i].offerPage2.filter(x => (filterIndex === 0 ? x.benefitsSavings > 0 : filterIndex === 2 ? x.benefitsRewards > 0 : true))
    }
  }

  // const scrollTop = () => {
  //   try {
  //     // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: "auto",
  //     })
  //   } catch (error) {
  //     // just a fallback for older browsers
  //     window.scrollTo(0, 0)
  //   }
  // }

  const handleSetOpenFilter = isOpen => {
    if (isOpen === openFilter) {
      return
    }
    setOpenFilter(isOpen)
    const data = {
      action: isOpen ? "open" : "close",
    }
    // DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_OFFERS_FILTER_MENU, data)
  }

  // useEffect(() => {
  //   window.onscroll = () => {
  //     if (openFilter) {
  //       handleSetOpenFilter(false)
  //     }
  //   }
  // }, [openFilter])

  const handleFilterOnClick = props => {
    const data = {
      filter: props.index === 0 ? "more savings" : props.index === 2 ? "more rewards" : "overall value",
      ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }),
    }
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_OFFERS_FILTER_SELECTED, data)
    setFilterIndex(props.index)
    handleSetOpenFilter(true)
    // console.log("scroll finish, filter opened", openFilter)
  }

  const IndexButtonView = props => (
    <Clickable.Text
      style={{ margin: "0", width: "34%", height: "56px", color: filterIndex !== props.index ? "#00AFC5" : null, backgroundColor: filterIndex !== props.index ? "#F2FBFC" : null }}
      // className={styles.nextButton}
      onClick={() => handleFilterOnClick(props)}
    >
      {props.children}
    </Clickable.Text>
  )

  const mainHeader = rewardsHeading => {
    return (
      <div className="header2">
        <div style={{ height: "56px" }}>
          <div className={styles.filterHeader}>
            <div style={{ height: "100%", width: "33%" }} >
              {/* <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", margin: "0 auto 0 auto" }} onClick={() => handleSetOpenFilter(!openFilter)}>{filterIndex > -1 ? <LazyLoadImage src={openFilter ? "/images/filter_blue_open2.png" : "/images/filter_black_closed2.png"} style={{width: "37px", height: "18px"}} alt="" /> : null}</div> */}
              <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto 0 auto" }}></div>
            </div>
            <div style={{ height: "100%", width: "34%", borderRight: "0.5px solid #D2D2D2", borderLeft: "0.5px solid #D2D2D2", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ textAlign: "center", display: "block", lineHeight: "18px", fontSize: "16px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>
                Fees &
                <br />
                Interest
              </div>
            </div>
            <div style={{ height: "100%", width: "33%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ textAlign: "center", display: "block", lineHeight: "18px", fontSize: "16px", color: "#777777", fontFamily: "Arboria-Book, sans-serif", whiteSpace: "break-space", wordSpacing: "100px" }}>{rewardsHeading}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const listHeader = txt => (
    <div className={styles.listHeader}>
      <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", weight: 700, lineHeight: "40px", fontSize: "20px" }}>{txt}</div>
    </div>
  )

  const offerView = (offer, group, ranking, isLast) => {
    //console.log(group)
    const productId = offer ? offer.productId : jsonInput.productId
    const providerID = offer ? offer.providerId : group.providerId
    const product = store.getState().main.products.filter(item => item.id === productId)[0]
    const provider = store.getState().main.provider.filter(item => item.id === providerID)[0]

    const participant = offer ? offer.participant : group.participant
    const hasRewards = offer ? offer.rewardProgramId !== 0 : false
    const baseDataToCollect = { offerRanking: offer ? ranking : null, public: offer ? offer.public : null, promoted: offer ? offer.promoted : null }

    let alerts = ""
    if (offer) {
      if (offer.debug && offer.debug.fxAlert) alerts += " FX"
      if (offer.debug && offer.debug.btAlert) alerts += " BT"
      if (offer.debug && offer.debug.ppAlert) alerts += " PP"
      if (offer.debug && offer.debug.bonusPointsAlert) alerts += " BP"
      if (offer.debug && offer.debug.afWaivedAlert) alerts += " AF"
    }

    const offerBenefits = () => {
      return offer ? (
        <div style={{ display: "flex", height: "70px", width: "100%", marginBottom: "30px" }}>
          <div style={{ height: "100%", width: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>{provider && <LazyLoadImage src={providerImage(provider.id)} style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "66%" }} alt="" />}</div>
          {offer.benefitsSavings === 0 ? (
            // <div>
            //   <div style={{ height: "100%", width: "34%", borderRight: "0.5px solid #D2D2D2", borderLeft: "0.5px solid #D2D2D2" }}>
            //     <div style={{ height: "20px", lineHeight: "20px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>no savings</div>
            //   </div>
            // </div>
            <div style={{ height: "100%", width: "34%", borderRight: "0.5px solid #D2D2D2", borderLeft: "0.5px solid #D2D2D2" }}>
              <div style={{ textAlign: "center", lineHeight: "61px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>no savings</div>
            </div>
          ) : (
            <div style={{ height: "100%", width: "34%", borderRight: "0.5px solid #D2D2D2", borderLeft: "0.5px solid #D2D2D2" }}>
              {Math.floor(Math.abs(offer.benefitsSavings)) < 100 ? (
                <div style={{ textAlign: "center", display: "block", lineHeight: "61px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>similar cost</div>
              ) : (
                <div>
                  <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>{offer.benefitsSavings >= 0 ? "you could save" : "pay more"}</div>
                  <div style={{ textAlign: "center", height: "29px", display: "block", lineHeight: "29px", fontSize: "24px", color: offer.benefitsSavings >= 0 ? "#00AFC5" : "#E7527A", fontFamily: "Arboria-Bold, sans-serif" }}>${Math.floor(Math.abs(offer.benefitsSavings)).toLocaleString()}</div>
                  <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>over 3 years</div>
                </div>
              )}
            </div>
          )}

          {offer.rewardProgramId === 0 ? (
            <div style={{ height: "100%", width: "33%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ height: "20px", lineHeight: "20px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>{"no " + (jsonResponse.rewardsCategory === "general_rewards" || jsonResponse.rewardsCategory === "no_rewards" ? "rewards" : "points")}</div>
            </div>
          ) : hasRewards && offer.benefitsRewards !== 0 ? (
            <div style={{ height: "100%", width: "33%" }}>
              {Math.abs(Math.floor(offer.benefitsRewards)) < 100 ? (
                <div style={{ textAlign: "center", display: "block", lineHeight: "61px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>{jsonResponse.rewardsCategory === "general_rewards" || jsonResponse.rewardsCategory === "no_rewards" ? "similar rewards" : "similar points"}</div>
              ) : (
                <div>
                  <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>{(offer.benefitsRewards >= 0 ? "earn more " : "earn less ") + (jsonResponse.rewardsCategory === "general_rewards" || jsonResponse.rewardsCategory === "no_rewards" ? "rewards" : "points")}</div>
                  <div style={{ textAlign: "center", height: "29px", display: "block", lineHeight: "29px", fontSize: "24px", color: offer.benefitsRewards >= 0 ? "#00AFC5" : "#E7527A", fontFamily: "Arboria-Bold, sans-serif" }}>{jsonResponse.rewardsCategory === "general_rewards" || jsonResponse.rewardsCategory === "no_rewards" ? (offer.benefitsRewards >= 0 ? "+$" : "-$") + Math.abs(Math.floor(offer.benefitsRewards)).toLocaleString() : (offer.benefitsPoints >= 0 ? "+" : "-") + Math.abs(Math.floor(offer.benefitsPoints)).toLocaleString()}</div>
                  <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>over 3 years</div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ height: "100%", width: "33%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ textAlign: "center", lineHeight: "20px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>{"no additional " + (jsonResponse.rewardsCategory === "general_rewards" || jsonResponse.rewardsCategory === "no_rewards" ? "rewards" : "points")}</div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div style={{ display: "flex", height: "70px", width: "100%", marginBottom: "20px" }}>
            <div style={{ height: "100%", width: "33%" }}>{group.offerType === "Stay" ? provider && <LazyLoadImage src={providerImage(provider.id)} style={{ display: "block", marginLeft: "auto", marginRight: "auto", height: "100%" }} alt="" /> : <LazyLoadImage src="/images/provider/00000.png" style={{ display: "block", marginLeft: "auto", marginRight: "auto", height: "100%" }} alt="" />}</div>
            <div style={{ height: "100%", width: "67%", borderLeft: "0.5px solid #D2D2D2", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ textAlign: "center", lineHeight: "20px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>No offers</div>
            </div>
          </div>
          {/* {group.offerType === "Stay" ? (
              <div style={{ marginTop: "20px" }}>
              {provider.nudge ? <Nudge provider={provider} jsonInput={props.location.state.jsonInput} /> : null}
            </div>
            ) : null} */}
        </div>
      )
    }

    const offerButtons = () => {
      const dataToCollect = { baselineProductId: jsonInput.productId, baselineProviderId: jsonInput.providerId, landingPageUrl: offer.landingPageUrl, offerId: offer.offerId, offerType: group.offerType, providerId: provider.id, ...baseDataToCollect }

      return (
        <div style={{ marginTop: 40 }}>
          {offer.public === false ? (
            // <Clickable.Text style={{ marginTop: "30px", marginBottom: "30px" }} secondary comp={3} className="survey-button" onClick={() => {}} to={"/benefits/register-mobile"} component={Link}>
            //   Learn more
            // </Clickable.Text>
            <div style={{ marginTop: "30px", marginBottom: "30px", width: "100%", display: "flex", justifyContent: "space-between" }}>
              {((group.offerType === "Stay" && (store.getState().main.stayButtonFlag || participant)) || (group.offerType !== "Stay" && offer.landingPageUrl !== null && participant) && offer.active === "Y") ? (
                <Clickable.Text comp={6} className="offers-view-button" disabled={group.offerType === "Stay" && isQA ? true : false} onClick={() => (isQA ? AppManager.getInstance().getOffer(group.offerType, offer.offerId, offer, provider.id, provider.name) : AppManager.getInstance().getOfferSurvey(offer.offerId, provider, group.offerType, offer, dataToCollect))}>
                  Go to offer
                </Clickable.Text>
              ) : (
                <div style={{ width: "33%" }} />
              )}
              <Clickable.Text
                // backgroundColor="#e5f7f9"
                // color="#00afc5"
                // selectedColor="#cceff3"
                secondary
                comp={6}
                className="offers-view-button"
                onClick={() => {
                  //console.log("offer:", offer)
                  jsonInput.offerId = offer.offerId
                  if (!isQA) {
                    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_OFFER_DETAILS_VIEWED, { offerId: offer.offerId, providerId: provider.id, page: "PRICING_SUMMARY", ...dataToCollect, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
                  }

                  //console.log("jsonInput:", jsonInput)
                  if (isQA) {
                    API.getOfferDetailsQA(jsonInput, responseJson => {
                      //console.log("responseJson:", responseJson)
                      navigate("/offer-qa/offer-details", {
                        state: {
                          responseJson,
                        },
                      })
                    })
                  } else {
                    API.getOfferDetails(jsonInput, responseJson => {
                      //console.log("responseJson:", responseJson)
                      navigate("/credit-cards/offer-details", {
                        state: {
                          responseJson,
                          dataToCollect,
                        },
                      })
                    })
                  }
                }}
              >
                Learn more
              </Clickable.Text>
            </div>
          ) : (
            <div style={{ marginTop: "30px", marginBottom: "30px", width: "100%", display: "flex", justifyContent: "space-between" }}>
              {((group.offerType === "Stay" && (store.getState().main.stayButtonFlag || participant)) || (group.offerType !== "Stay" && offer.landingPageUrl !== null && participant) && offer.active === "Y") ? (
                <Clickable.Text comp={6} className="offers-view-button" disabled={group.offerType === "Stay" && isQA ? true : false} onClick={() => (isQA ? AppManager.getInstance().getOffer(group.offerType, offer.offerId, offer, provider.id, provider.name) : AppManager.getInstance().getOfferSurvey(offer.offerId, provider, group.offerType, offer, dataToCollect))}>
                  Go to offer
                </Clickable.Text>
              ) : (
                <div style={{ width: "33%" }} />
              )}
              <Clickable.Text
                // backgroundColor="#e5f7f9"
                // color="#00afc5"
                // selectedColor="#cceff3"
                secondary
                comp={6}
                className="offers-view-button"
                onClick={() => {
                  //console.log("offer:", offer)
                  DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_OFFER_DETAILS_VIEWED, { offerId: offer.offerId, providerId: provider.id, page: "PRICING_SUMMARY", ...dataToCollect, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })

                  jsonInput.offerId = offer.offerId
                  //console.log("jsonInput:", jsonInput)
                  if (isQA) {
                    API.getOfferDetailsQA(jsonInput, responseJson => {
                      //console.log("responseJson:", responseJson)
                      navigate("/offer-qa/offer-details", {
                        state: {
                          responseJson,
                        },
                      })
                    })
                  } else {
                    API.getOfferDetails(jsonInput, responseJson => {
                      //console.log("responseJson:", responseJson)
                      navigate("/credit-cards/offer-details", {
                        state: {
                          responseJson,
                          dataToCollect,
                        },
                      })
                    })
                  }
                }}
              >
                Learn more
              </Clickable.Text>
            </div>
          )}

          {store.getState().survey.flags && store.getState().survey.flags.debugInfo ? (
            // {true ? (
            <div>
              {"Total Benefit = " + offer.totalBenefits}
              <br />
              {"Savings = " + offer.benefitsSavings}
              <br />
              {"Rewards = " + offer.benefitsRewards + " value, " + offer.benefitsPoints + " points"}
              <br />
              {"Participant = " + offer.participant}
              <br />
              {"Promoted = " + offer.promoted}
              <br />
              {"Suppressed = " + offer.debug.suppressed}
              <br />
              {"Offer ID = " + offer.offerId}
              <br />
              {"Alerts = " + alerts}
              <br />
              <br />
            </div>
          ) : null}
        </div>
      )
    }

    return (
      <div>
        {offer && group.offerType === "Stay" ? (
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", verticalAlign: "middle", marginTop: "20px" }}>
            <LazyLoadImage src="/images/icon_special_offer.png" style={{height: "30px", width: "30px"}} alt="" />
            <div style={{ fontSize: "16px", color: "#00AFC5", fontFamily: "Arboria-Bold, sans-serif", marginLeft: "16px" }}>{offer.benefitsSavings > offer.benefitsRewards ? "Save without reapplying" : "Upgrade"}</div>
          </div>
        ) : null}
        {offer && offer.public === false ? (
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", verticalAlign: "middle", marginTop: "20px" }}>
            <LazyLoadImage src="/images/icon_special_offer.png" style={{height: "30px", width: "30px"}} alt="" />
            <div style={{ fontSize: "16px", color: "#00AFC5", fontFamily: "Arboria-Bold, sans-serif", marginLeft: "16px" }}>Special</div>
          </div>
        ) : null}
        {offer && offer.promoted === true ? (
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", verticalAlign: "middle", marginTop: "20px" }}>
            <LazyLoadImage src="/images/icon_promoted_offer.png" style={{height: "30px", width: "30px"}} alt="" />
            <div style={{ fontSize: "16px", color: "#00AFC5", fontFamily: "Arboria-Bold, sans-serif", marginLeft: "16px" }}>Promotion</div>
          </div>
        ) : null}
        <div style={{ marginTop: "10px" }}>
          {!isQA ? offerBenefits() : null}

          {offer ? <ProductView product={product} isNameLong={true} isSchemeFb={true} /> : null}

          {offer ? offerButtons() : null}

          {store.getState().survey.flags && store.getState().survey.flags.debugInfo ? (
            // {true ? (
            <div>
              {"Total Benefit = " + offer.totalBenefits}
              <br />
              {"Savings = " + offer.benefitsSavings}
              <br />
              {"Rewards = " + offer.benefitsRewards + " value, " + offer.benefitsPoints + " points"}
              <br />
              {"Participant = " + offer.participant}
              <br />
              {"Promoted = " + offer.promoted}
              <br />
              {"Suppressed = " + offer.debug.suppressed}
              <br />
              {"Offer ID = " + offer.offerId}
              <br />
              {"Alerts = " + alerts}
              <br />
              <br />
            </div>
          ) : null}

          {isQA && offer ? (
            <div style={{ marginBottom: "30px" }}>
              <div>{offer.offerId}</div>
              <div>
                {offer.s1}
                {offer.s2} {offer.s3}
                {offer.s4} {offer.s5}
                {offer.s6}
              </div>
            </div>
          ) : null}

          {isLast ? null : <div style={{ width: "-webkit-calc(100% + 38px)", height: "5px", borderBottom: "0.5px solid #D2D2D2", marginLeft: "-19px", marginTop: "40px" }} />}
        </div>
      </div>
    )
  }

  const offerRanking = (offerType, groupIndex, pageIndex, offerIndex) => {
    const typeValue = offerType === "Stay" ? "S" : offerType === "Go" ? "G" : "X"
    return `${typeValue}${groupIndex + 1}-${pageIndex}.${offerIndex + 1}`
  }

  const page1Offers = (group, groupIndex) => {
    return group.offerPage1.length > 0 ? (
      group.offerPage1.map((o, i) => {
        return <div key={i}>{offerView(o, group, offerRanking(group.offerType, groupIndex, 1, i), group.offerPage1.length - 1 === i)}</div>
      })
    ) : (
      <div>{offerView(null, group, null, true)}</div>
    )
  }
  const page2Offers = (group, groupIndex) => {
    return group.offerPage2.map((o, i) => {
      return <div key={i}>{offerView(o, group, offerRanking(group.offerType, groupIndex, 2, i), group.offerPage2.length - 1 === i)}</div>
    })
  }

  return (
    <div className="page-container2">
      <div className="survey-container">
        {mainHeader(jsonResponse.rewardsHeading)}
        {openFilter ? (
          <div className={styles.filterContainer}>
            <div className={styles.buttonsContainer}>
              <IndexButtonView index={0}>
                more <br />
                savings
              </IndexButtonView>
              <IndexButtonView index={1}>
                overall <br />
                value
              </IndexButtonView>
              <IndexButtonView index={2}>
                more <br />
                rewards
              </IndexButtonView>
            </div>
          </div>
        ) : null}
        {offerGroups.map((g, groupIndex) => {
          const provider = store.getState().main.provider.filter(item => item.id === g.providerId)[0]
          return (
            <div key={groupIndex}>
              {g.offerType === "Stay" ? listHeader(provider.name + " offers") : listHeader(g.offerType === "Go" ? "Top market offers" : provider.name + " offers")}
              {/* <div style={{ backgroundColor: "#00AFC5", height: "20px", marginBottom: "30px", marginTop: "30px" }}>
                <div style={{ backgroundColor: "#00AFC5", marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", weight: 700, lineHeight: "40px", fontSize: "18px" }}>Page2</div>
              </div> */}
              {store.getState().survey.flags && store.getState().survey.flags.debugInfo ? "Page1" : null}
              {page1Offers(g, groupIndex)}
              {store.getState().survey.flags && store.getState().survey.flags.debugInfo ? "Page2" : null}
              {g.offerPage2.length > 0 ? (
                <div>
                  <div
                    style={{ cursor: "pointer", color: "#202020", width: "100%", height: "40px", display: "flex", justifyContent: "space-between", marginTop: "50px", alignItems: "center" }}
                    onClick={() => {
                      DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_OFFERS_SEE_MORE_SELECTED, { offerType: g.offerType, providerId: g.providerId, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
                      setOpenIndex(groupIndex === openIndex ? -1 : groupIndex)
                      store.offerOpenIndex = groupIndex === openIndex ? -1 : groupIndex
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {
                      setOpenIndex(groupIndex === openIndex ? -1 : groupIndex)
                      store.offerOpenIndex = groupIndex === openIndex ? -1 : groupIndex
                    }}
                  >
                    <div style={{ textAlign: "left", height: "18px", display: "block", lineHeight: "18px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>See more {g.offerType !== "Go" && provider.name} offers</div>
                    {groupIndex === openIndex ? <ExpandLess /> : <ExpandMore />}
                  </div>
                  <Collapse in={openIndex === groupIndex} timeout="auto" unmountOnExit>
                    {page2Offers(g, groupIndex)}
                  </Collapse>
                </div>
              ) : null}
            </div>
          )
        })}
        <div style={{ height: "5px", width: "-webkit-calc(100% + 38px)", borderBottom: "0.5px solid #D2D2D2", marginLeft: "-19px", marginTop: "40px", marginBottom: "20px" }} />
        <Disclaimer />
      </div>
    </div>
  )
}

export default OffersView
