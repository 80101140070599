import BanksListView from "components/content/banks-list-view"
import React from "react"

const BanksList = () => {
  return (
    <div className="container-center">
      <div className="home-container">
        <BanksListView />
      </div>
    </div>
  )
}

export default BanksList
