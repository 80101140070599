import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import SGTextfield from "../../textfield"
import { rewardProgramImage } from "../utils"
import * as styles from "./style.module.scss"
import CircularProgress from "@mui/material/CircularProgress"

export default function BenefitsSummary(props) {
  const provider = store.getState().main.provider.find(x => x.id === props.location.state.jsonInput.providerId)
  const product = provider.products.find(x => x.id === props.location.state.jsonInput.productId)
  const rewards = store.getState().main.rewardProgram.find(x => x.id === product.rewardProgram)
  const [filterIndex, setFilterIndex] = useState(1)
  const [count, setCount] = useState(0)
  const [maxSavings, setMaxSavings] = useState(0)
  const [maxRewards, setMaxRewards] = useState(0)
  const [maxPoints, setMaxPoints] = useState(0)
  const [registerIndex, setRegisterIndex] = useState(props.location.state.registerIndex ? props.location.state.registerIndex : 0)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let tempCount = 0
    let tempSavings = 0
    let tempRewards = 0
    let tempPoints = 0
    props.location.state.responseJson.offerGroups.map(offerGroup => {
      tempCount += offerGroup.offerPage1.length
      tempCount += offerGroup.offerPage2 ? offerGroup.offerPage2.length : 0

      offerGroup.offerPage1.map(offer => {
        if (offer.benefitsSavings > tempSavings) {
          tempSavings = offer.benefitsSavings
        }
        if (offer.benefitsRewards > tempRewards) {
          tempRewards = offer.benefitsRewards
          tempPoints = offer.benefitsPoints
        }
      })
    })
    setCount(tempCount)
    setMaxSavings(tempSavings)
    setMaxRewards(tempRewards)
    setMaxPoints(tempPoints)
  }, [])

  function scrollTop() {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0)
    }
  }

  const IndexButtonView = props => (
    <Clickable.Text
      style={{ width: "33%", height: "56px", color: filterIndex !== props.index ? "#00AFC5" : null, backgroundColor: filterIndex !== props.index ? "#F2FBFC" : null }}
      className={styles.nextButton}
      onClick={() => {
        const data = {
          filter: props.index === 0 ? "more savings" : props.index === 2 ? "more rewards" : "overall value",
          ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }),
        }
        DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_OFFERS_FILTER_SELECTED, data)
        setFilterIndex(props.index)
      }}
    >
      {props.children}
    </Clickable.Text>
  )

  const benefitsSummaryView = () => {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <Typography.H6>Congratulations!</Typography.H6>
          <Typography.P>
            We found <span style={{ color: "#202020", fontFamily: "Arboria-Bold" }}>{count}</span> better deals
          </Typography.P>
        </div>

        <div style={{ display: "flex", justifyContent: "space-around", marginTop: "60px" }}>
          {maxSavings > 0 ? (
            <div>
              <LazyLoadImage className={styles.beneftisDivChild} src="/images/icon_save.png" style={{width: "84px", height: "57px"}} alt="" />
              <Typography.P className={styles.beneftisDivChild} style={{ marginTop: "20px" }}>
                you could save
              </Typography.P>
              <Typography.H3 className={styles.beneftisDivChild} comp="benefit">
                ${maxSavings.toLocaleString()}
              </Typography.H3>
              <Typography.P className={styles.beneftisDivChild}>over 3 years</Typography.P>
            </div>
          ) : null}
          {maxSavings > 0 && maxRewards > 0 ? (
            <div style={{ display: "flex", alignSelf: "center" }}>
              <Typography.P comp={"small-bold"}>or</Typography.P>
            </div>
          ) : null}
          {maxRewards > 0 ? (
            <div>
              <LazyLoadImage className={styles.beneftisDivChild} src={rewards.rewardsCategory === "no_rewards" || rewards.rewardsCategory === "general_rewards" ? "/images/icon_rewards.png" : rewardProgramImage(rewards.id)} style={{width: "84px", height: "57px"}} alt="" />
              <Typography.P className={styles.beneftisDivChild} style={{ marginTop: "20px" }}>
                {maxSavings <= 0 ? "you could " : ""}earn more {rewards.rewardsCategory === "general_rewards" || rewards.rewardsCategory === "no_rewards" ? "rewards" : "points"}
              </Typography.P>
              <Typography.H3 className={styles.beneftisDivChild} comp="benefit">
                +{rewards.rewardsCategory === "general_rewards" || rewards.rewardsCategory === "no_rewards" ? "$" + maxRewards.toLocaleString() : maxPoints.toLocaleString()}
              </Typography.H3>
              <Typography.P className={styles.beneftisDivChild}>over 3 years</Typography.P>
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "60px" }}>
          {maxSavings > 0 && maxRewards > 0 ? (
            <div>
              <Typography.P comp={"medium-black"} style={{ textAlign: "center" }}>
                What do you value most?
              </Typography.P>
              <div className={styles.buttonsContainer}>
                <IndexButtonView index={0}>
                  more <br />
                  savings
                </IndexButtonView>
                <IndexButtonView index={1}>
                  overall <br />
                  value
                </IndexButtonView>
                <IndexButtonView index={2}>
                  more <br />
                  rewards
                </IndexButtonView>
              </div>
            </div>
          ) : null}

          <Clickable.Text
            className={styles.nextButton}
            comp={3}
            style={{ marginTop: "50px" }}
            onClick={() => {
              const data = {
                filter: filterIndex === 0 ? "more savings" : filterIndex === 2 ? "more rewards" : "overall value",
                filterIsDisplayed: maxSavings > 0 && maxRewards > 0 ? "true" : "false",
                ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }),
              }
              DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_BENEFITS_SUMMARY_CONFIRMED, data)
              navigate("/credit-cards/offers", {
                state: {
                  responseJson: props.location.state.responseJson,
                  jsonInput: props.location.state.jsonInput,
                  filterIndex: maxSavings > 0 && maxRewards > 0 ? filterIndex : -1,
                },
              })
            }}
          >
            See better deals
          </Clickable.Text>
        </div>
      </div>
    )
  }

  const registerView = () => {
    const handleSubmit = () => {
      setIsLoading(true)
      const data = {
        name: firstName,
        last_name: lastName,
        email: email,
        purpose: "NO_OFFERS",
        ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }),
      }
      DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_REGISTERED, data)
      data["survey_id"] = store.getState().survey.surveyId
      data["provider_id"] = provider.id
      data["provider_name"] = provider.name
      data["product_id"] = product.id
      data["product_name"] = product.name
      API.submitNoOffersRegistration(data, responseJson => {
        setRegisterIndex(2)
      })
      scrollTop()
    }

    const onChangeEmail = e => {
      setEmail(e.target.value)
      setIsEmailValid(e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    }

    return registerIndex === 1 ? (
      <div style={{ opacity: isLoading ? 0.4 : 1 }}>
        <LazyLoadImage src="/images/icon_congratulations.png" style={{ width: "75px", height: "75px", display: "block", margin: "20px auto 0 auto" }} alt="" />
        <div style={{ textAlign: "center" }}>
          <Typography.H6 myMarginTop="15px">Register to save</Typography.H6>
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Typography.P>Register below so we can help ensure your current deal remains competitive.</Typography.P>
        </div>

        <form autoComplete="on">
          <div style={{ marginTop: "40px" }}>
            <SGTextfield className={styles.tf} id="outlined-basic" label="First Name" autoComplete="given-name" onChange={e => setFirstName(e.target.value)} />
          </div>
          <div>
            <SGTextfield className={styles.tf} id="outlined-basic" label="Last Name" autoComplete="family-name" onChange={e => setLastName(e.target.value)} />
          </div>
          <div>
            <SGTextfield className={styles.tf} id="outlined-basic" label="Email address" autoComplete="email" onChange={onChangeEmail} />
          </div>

          <Clickable.Text style={{ marginTop: "60px" }} comp={3} className="survey-button" onClick={handleSubmit} disabled={!firstName || !lastName || !isEmailValid || isLoading}>
            Submit
          </Clickable.Text>
        </form>
      </div>
    ) : registerIndex === 2 ? (
      <div>
        <div style={{ textAlign: "center", marginTop: "110px" }}>
          <Typography.H6 myMarginTop="15px">Thank you</Typography.H6>
          <Typography.P style={{ marginTop: "20px" }}>We’ll notify you of key market changes and let you know if your deal becomes uncompetitive.</Typography.P>
        </div>
        <Clickable.Text style={{ marginTop: "215px" }} comp={3} className="survey-button" onClick={() => navigate("/")}>
          Home
        </Clickable.Text>
      </div>
    ) : (
      <div>
        <LazyLoadImage src="/images/icon_congratulations.png" style={{ width: "75px", height: "75px", display: "block", margin: "20px auto 0 auto" }} alt="" />
        <div style={{ textAlign: "center" }}>
          <Typography.H6 myMarginTop="15px">Congratulations!</Typography.H6>
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Typography.P>Based on our reviewed of 200+ offers it looks like you’re already on a competitive deal.</Typography.P>
          <br />
          <Typography.P>However, the credit card market is constantly changing, so it’s important to monitor your deal to ensure it remains competitive.</Typography.P>
        </div>
        <Clickable.Text
          className={styles.nextButton}
          comp={3}
          style={{ marginTop: "150px" }}
          onClick={() =>
            navigate("/credit-cards/benefits-summary-registration", {
              state: {
                registerIndex: 1,
                jsonInput: props.location.state.jsonInput,
                responseJson: props.location.state.responseJson,
              },
            })
          }
        >
          Continue
        </Clickable.Text>
      </div>
    )
  }

  return (
    <div className="page-container2">
      <div className="survey-container">{maxSavings > 0 || maxRewards > 0 ? benefitsSummaryView() : registerView()}</div>
    </div>
  )
}
