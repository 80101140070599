import CreditCardProduct from "../product-models/CreditCardProduct"
import Feature from "../Feature"
import Provider from "../Provider"
import Reward from "../Reward"
import Content from "../Content"
import CreditCardProvider from "../provider-models/CreditCardProvider"
import CreditCardFeature from "../feature-models/CreditCardFeature"
import CreditCardReward from "../reward-models/CreditCardReward"
import contentJson from "../../../../../artifacts/cc-content.json"

// Stores the currently-being-typechecked object for error messages.
let obj: any = null
export default class CreditCardContent extends Content {
  public static ccData = CreditCardContent.Parse(contentJson)

  // = AUTOMATED FUNCTIONS =
  // Parse
  public static Parse(d: object): Content {
    return CreditCardContent.Create(d)
  }

  // Create
  public static Create(d: any, field: string = "root"): Content {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }

    // Create Rewards Dictionary
    const rewardsDict: { [id: string]: Reward } = {}
    Object.keys(d.rewards).forEach(rewardId => {
      rewardsDict[rewardId] = CreditCardReward.Create(d.rewards[rewardId], field + ".rewards." + rewardId)
    })
    d.rewards = rewardsDict
    // Create Provider Dictionary
    const providersDict: { [id: string]: Provider } = {}
    Object.keys(d.providers).forEach(providerId => {
      providersDict[providerId] = CreditCardProvider.Create(d.providers[providerId], d.rewards, field + ".providers." + providerId)
    })
    d.providers = providersDict

    // Initialise top values
    const valuesDict: { [valueName: string]: { [top: string]: Number } } = {}
    const allNames: (keyof CreditCardProduct)[] = ["pointsPerSpend", "totalAfPrimary", "purchaseRate"]
    allNames.forEach(name => {
      const valueArray: any[] = []
      Object.keys(d.providers).forEach(providerId => {
        const provider: Provider = d.providers[providerId]
        Object.keys(provider.products).forEach(productId => {
          const product: CreditCardProduct = provider.products[productId] as CreditCardProduct
          let value: any = product[name]
          if (!(name === "pointsPerSpend" && (value === 0 || value === null))) {
            if (name === "pointsPerSpend" && d.rewards[product.rewardProgramId].value !== null) {
              value = value * d.rewards[product.rewardProgramId].value
            }
            if (!(name === "pointsPerSpend" && product.schemeType === 4)) {
              valueArray.push(value)
            }
          }
        })
      })
      if (name === "pointsPerSpend") {
        valueArray.sort((a, b) => b - a)
      } else {
        valueArray.sort((a, b) => a - b)
      }
      const top1 = valueArray.length / 100
      const top1Value: Number = valueArray[Math.ceil(top1)]
      const top5 = valueArray.length / 20
      const top5Value: Number = valueArray[Math.ceil(top5)]
      const top10 = valueArray.length / 10
      const top10Value: Number = valueArray[Math.ceil(top10)]
      const top25 = valueArray.length / 4
      const top25Value: Number = valueArray[Math.ceil(top25)]
      const top75 = (valueArray.length / 4) * 3
      const top75Value: Number = valueArray[Math.ceil(top75)]
      valuesDict[name] = { "1": top1Value, "5": top5Value, "10": top10Value, "25": top25Value, "75": top75Value }
    })
    Object.keys(d.providers).forEach(providerId => {
      const provider: Provider = d.providers[providerId]
      Object.keys(provider.products).forEach(productId => {
        const product: CreditCardProduct = provider.products[productId] as CreditCardProduct
        const valuesDictKeys = ["1", "5", "10", "25"]
        let pps = product.pointsPerSpend
        if (d.rewards[product.rewardProgramId].value !== null) {
          pps = pps * d.rewards[product.rewardProgramId].value
        }
        for (const topA of valuesDictKeys) {
          if (pps >= (valuesDict.pointsPerSpend[topA] as number)) {
            product.topPPS = topA
            break
          }
        }
        if (product.topPPS === null) {
          if (pps >= (valuesDict.pointsPerSpend["75"] as number)) {
            product.topPPS = "75"
          } else {
            product.topPPS = "100"
          }
        }
        for (const topB of valuesDictKeys) {
          if (product.totalAfPrimary <= (valuesDict.totalAfPrimary[topB] as number)) {
            product.topFee = topB
            break
          }
        }
        for (const topC of valuesDictKeys) {
          if (product.purchaseRate <= (valuesDict.purchaseRate[topC] as number)) {
            product.topRate = topC
            break
          }
        }
      })
    })

    // Create Feature Dictionary
    const featuresDict: { [name: string]: Feature } = {}
    const features = [
      { name: "Low Rate", urlSlug: "low-rate-credit-cards", defaultSort: "Purchase Rate" },
      { name: "Interest Free", urlSlug: "interest-free-credit-cards", defaultSort: "Account Fees" },
      { name: "Balance Transfer", urlSlug: "balance-transfer-credit-cards", defaultSort: "Balance Transfer Offer" },
      { name: "0% Purchase", urlSlug: "zero-purchase-credit-cards", defaultSort: "Purchase Rate Offer" },
      { name: "No Annual Fee", urlSlug: "no-annual-fee-credit-cards", defaultSort: "Purchase Rate" },
      { name: "Cash Back", urlSlug: "cash-back-credit-cards", defaultSort: "Cash Back Offer" },
      { name: "No Foreign Transaction Fee", urlSlug: "no-foreign-transaction-fee-credit-cards", defaultSort: "Account Fees" },
      { name: "Travel Insurance", urlSlug: "travel-insurance-credit-cards", defaultSort: "Account Fees" },
    ]
    features.forEach(feature => {
      featuresDict[feature.name] = CreditCardFeature.Create(feature, field + ".features." + feature.name)
    })
    d.popularFeatures = featuresDict
    checkString(d.date_updated, false, field + ".date_updated")
    d.date_updated = d.date_updated.substring(0, 10)
    return new CreditCardContent(d)
  }
  private constructor(d: any) {
    super(d)
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}
