import * as Clickable from "components/clickable"
import HelpDialog from "components/help-dialog"
import { Typography } from "components/typography"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as style from "./style.module.scss"

export const ListHeader = ({ title, helpInfo, hideHelpButton, ...other }) => (
  <div className={style.header}>
    <Typography.H6 comp="list-heading">{title}</Typography.H6>
    {hideHelpButton ? null : <HelpDialog info={helpInfo} {...other} />}
  </div>
)

export const ListItem = ({ selected, text, img, ...other }) => (
  <div>
    <Clickable.View className={style.item} {...other}>
      <div className={style.itemContainer} style={{ width: "100%" }}>
        <div className={style.itemContainer}>
          {img && (
            <div className={style.itemImg}>
              <LazyLoadImage className={style.img} src={img} alt="" />
            </div>
          )}
          <div className={img ? style.itemText : style.itemText2}>
            <Typography.H6 comp="list-item">{text}</Typography.H6>
          </div>
        </div>
        {selected && (
          <div className={style.itemSelectedImg}>
            <LazyLoadImage className={style.img} src="/images/selected_tick.png" alt="" />
          </div>
        )}
      </div>
    </Clickable.View>
  </div>
)
