import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import SGTextfield from "../../textfield"
import { cardImage, providerImage } from "../utils"
import * as styles from "./style.module.scss"

const ProductTransfer = props => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(false)
  const instructionsSent = props.location.state.instructionsSent ? props.location.state.instructionsSent : false

  if (!props.location.state || !props.location.state.providerId) {
    typeof window !== "undefined" && navigate("/credit-cards/instructions")
    return null
  }

  //console.log("props.location.state:", props.location.state)
  const provider = store.getState().main.provider.filter(item => item.id === props.location.state.providerId)[0]
  const toProductName = props.location.state.offer ? props.location.state.offer.productName : props.location.state.jsonResponse.offer.productName
  const toProductId = props.location.state.offer ? props.location.state.offer.productId : props.location.state.jsonResponse.offer.productId
  //console.log("store.getState().survey.cards:", store.getState().survey.cards)
  const fromProductIndex = store.getState().survey.cards.length - 1
  const fromProductName = store.getState().survey.cards[fromProductIndex].product.name
  const fromProductId = store.getState().survey.cards[fromProductIndex].product.id

  const handleSubmit = () => {
    const purpose = "PRODUCT_TRANSFER_SUBMITTED"
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_REGISTERED, { firstname: name, lastname: lastName, email, purpose, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
    API.submitProductTransfer(
      {
        surveyId: store.getState().survey.surveyId,
        providerId: provider.id,
        name,
        lastName: lastName,
        purpose,
        providerName: provider.name,
        fromProductName,
        fromProductId,
        toProductName,
        toProductId,
        email,
      },
      responseJson => {
        // store.dispatch(clearSurvey());
        // navigate("/credit-cards/thank-you", {
        //   state: { isRegistered: true },
        // })
      },
    )

    navigate("/credit-cards/product-transfer/thank-you", {
      state: {
        ...props.location.state,
        instructionsSent: true,
      },
    })
  }

  const onChangeEmail = e => {
    setEmail(e.target.value)
    setIsEmailValid(e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 40 }}>
          <div style={{ color: "#202020", textAlign: "center", height: "24px", display: "block", lineHeight: "24px", fontSize: "22px", fontFamily: "Arboria-Bold, sans-serif" }}>Product Transfer</div>
          <LazyLoadImage src={providerImage(provider.id)} style={{height: "50px", width: "70px"}} alt="" />
        </div>

        <div style={{ width: "100%", display: "flex", height: "160px", justifyContent: "space-between" }}>
          <div className={styles.cardWidth}>
            <div style={{ height: "18px", lineHeight: "18px", fontSize: "16px", color: "#00AFC5", fontFamily: "Arboria-Bold, sans-serif", marginBottom: "10px" }}>from</div>
            <LazyLoadImage src={cardImage(fromProductId)} style={{height: "80px", width: "130px"}} alt="" />
            <div style={{ minHeight: "18px", lineHeight: "18px", fontSize: "16px", color: "#4D4D4D", fontFamily: "Arboria-Book, sans-serif", marginTop: "10px" }}>{fromProductName}</div>
          </div>

          <div style={{ maxWidth: "30%", display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <LazyLoadImage src="/images/icon_double_arrow.png" style={{height: "40px", width: "40px"}} alt="" />
          </div>

          <div className={styles.cardWidth}>
            <div style={{ height: "18px", lineHeight: "18px", fontSize: "16px", color: "#00AFC5", fontFamily: "Arboria-Bold, sans-serif", marginBottom: "10px" }}>to</div>
            <LazyLoadImage src={cardImage(toProductId)} style={{height: "80px", width: "130px"}} alt="" />
            <div style={{ minHeight: "18px", lineHeight: "18px", fontSize: "16px", color: "#4D4D4D", fontFamily: "Arboria-Book, sans-serif", marginTop: "10px" }}>{toProductName}</div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          {instructionsSent ? (
            <div>
              <Typography.H6 style={{ marginBottom: "15px" }}>Instructions sent!</Typography.H6>

              <Typography.P style={{ marginBottom: "15px" }}>You will soon receive an email with instructions on how to complete your product transfer.</Typography.P>

              <Typography.P>If you have any issues, please contact Stay or Go by reply email.</Typography.P>

              <Clickable.Text
                style={{ marginTop: "80px" }}
                comp={3}
                className="survey-button"
                onClick={() => {
                  navigate("/")
                }}
              >
                Home
              </Clickable.Text>
            </div>
          ) : (
            <div>
              <Typography.P>
                Transferring products is <span style={{ color: "#00AFC5", fontFamily: "Arboria-Bold, sans-serif" }}>easy</span>, and you don’t need to reapply for credit.
              </Typography.P>
              <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "25px" }}>
                <div style={{ cursor: "default", marginLeft: 0 }} className="stepscarousel-button">
                  1
                </div>
                <Typography.P style={{ marginLeft: "25px" }}>Provide your contact details below</Typography.P>
              </div>
              <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "15px" }}>
                <div>
                  <div style={{ cursor: "default", marginLeft: "0px" }} className="stepscarousel-button">
                    2
                  </div>
                </div>
                <Typography.P style={{ marginLeft: "25px" }}>We’ll email a product transfer summary and instructions on how to accept</Typography.P>
              </div>

              <form autoComplete="on">
                <div style={{ marginTop: "40px" }}>
                  <SGTextfield className={styles.tf} id="outlined-basic" label="First Name" autoComplete="given-name" onChange={e => setName(e.target.value)} />
                </div>
                <div>
                  <SGTextfield className={styles.tf} id="outlined-basic" label="Last Name" autoComplete="family-name" onChange={e => setLastName(e.target.value)} />
                </div>
                <div>
                  <SGTextfield className={styles.tf} id="outlined-basic" label="Email address" autoComplete="email" onChange={onChangeEmail} />
                </div>

                <Clickable.Text style={{ marginTop: "30px" }} comp={3} className="survey-button" onClick={handleSubmit} disabled={!name || !lastName || !isEmailValid}>
                  Submit
                </Clickable.Text>
              </form>

              <Typography.P style={{ marginTop: "30px" }}>
                {"By submitting, you agree to the Stay or Go "}
                <a href="/privacy/" target="_blank" style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
                  Privacy Policy
                </a>
                {" and "}
                <a href="/terms/" target="_blank" style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
                  Terms of Use
                </a>
                {", and you consent to us contacting you about the products you have compared."}
              </Typography.P>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductTransfer
