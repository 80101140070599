// extracted by mini-css-extract-plugin
export var cardDetailsContainer = "style-module--card-details-container--88537";
export var img = "style-module--img--d05dd";
export var itemImg = "style-module--item-img--00771";
export var nextButton = "style-module--next-button--a001c";
export var row = "style-module--row--7203c";
export var rowImg = "style-module--row-img--b853f";
export var rowSpace = "style-module--row-space--b3bb3";
export var rowTextView = "style-module--row-text-view--d0882";
export var rowsSpace = "style-module--rows-space--8f09c";
export var subTitle = "style-module--sub-title--800a9";