import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React, { Component, createRef } from "react"
import ReactPlayer from "react-player"
import { connect } from "react-redux"
import { apis as API } from "@sog/sdk"

class Searching extends Component {
  constructor() {
    super()
    this.imgRef = createRef()
    this.mounted = createRef()
  }

  componentDidMount() {
    this.mounted.current = true
    // //console.log(JSON.stringify(this.props.surveyData, null, "\t"));
    if (!this.props.surveyData || this.props.surveyId === null) {
      // return <Redirect to="/survey/mycards" />;
    } else {
      // //console.log(JSON.stringify(this.props.surveyData, null, "\t"));

      setTimeout(() => {
        if (!this.mounted.current) {
          return
        }

        // API.estimate(this.props.surveyId, this.props.surveyData || [], responseJson => {
        //   this.props.dispatch(estimateAction(responseJson))
        //   navigate("/credit-cards/estimate")
        // })
        API.getOffers(this.props.location.state.jsonInput, responseJson => {
          navigate("/credit-cards/benefits-summary", {
            state: {
              responseJson,
              jsonInput: this.props.location.state.jsonInput,
            },
            replace: true,
          })
        })
      }, 2000)
    }
    // let obj = {
    //   cards: [
    //     {
    //       usage: {
    //         monthlyRepayment: 2,
    //         monthlySpend: 2,
    //         balanceRemaining: 4,
    //       },
    //       product: {
    //         id: 20010100,
    //         name: "Awards - Qantas",
    //         cardImages: 1,
    //         provider: 200,
    //         productCategory: 5,
    //         rewardProgram: 100,
    //         scheme: 2,
    //         provider_name: "Commbank",
    //         big4: true,
    //       },
    //     },
    //   ],
    //   uid: "210f5ad8-2c01-400d-bccc-9f58dadbf253",
    //   mainBank: 200,
    //   homeOwnership: 2,
    // };
    // NetworkManager.estimate("4fcf7b01-40c4-47f1-b718-977690e1ba21", obj, (responseJson) => {
    //   this.props.dispatch(estimateAction(responseJson));
    //   this.props.history.push("/survey/estimate");
    // });
  }

  componentWillUnmount() {
    this.mounted.current = false
  }

  render() {
    if (!this.props.surveyData || this.props.surveyId === null) {
      navigate("/credit-cards/select-card-provider")
      return null
    }

    return (
      <div className="page-container">
        <div className="survey-container">
          <Typography.H3 comp="survey" className="survey-header" style={{ textAlign: "center" }}>
            Finding you a better deal
          </Typography.H3>

          <div className="container-center">
            <div className="img-container" style={{ marginTop: "50px", width: "270px", height: "180px" }}>
              <ReactPlayer
                onReady={() => {
                  this.imgRef.current.style.display = "none"
                }}
                onError={() => {
                  this.imgRef.current.style.display = "block"
                }}
                url="/video/StayOrGo_Animation_SearchWheelLoop.mp4"
                playing={true}
                style={{
                  position: "absolute",
                  top: 0,
                  backgroundColor: "white",
                  left: 0,
                }}
                volume={0}
                muted={true}
                loop={true}
                playsinline={true}
                width="100%"
                height="100%"
              />
              <img ref={this.imgRef} className="img" style={{ height: "calc(100% - 2px)" }} src="/video/StayOrGo_Animation_SearchWheelLoop.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    surveyData: state.survey,
    surveyId: state.survey.surveyId,
  }
}

export default connect(mapStateToProps)(Searching)
