import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import ProgressBar from "../progress-bar"
import ProvidersListView from "../providers-list-view"

export const SelectBanks = props => {
  const progress = props.location.state.progress

  const banks = props.banks || []

  const didSelectProviders = value => {
    const providers = []
    value.forEach(p => {
      providers.push(p.id)
    })
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_BANKS_SELECTED, { ids: providers, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })

    props.location.state.jsonInput.providers = providers
    props.location.state.jsonInput.flags = store.getState().survey.flags

    navigate("/credit-cards/searching", {
      state: {
        jsonInput: props.location.state.jsonInput,
      },
    })
  }

  // insert user's current bank to banks.main in order if user's current bank is in banks.other
  const mainBanks = []
  const otherBanks = []

  banks.main.forEach(val => mainBanks.push(Object.assign({}, val)))
  banks.other.forEach(val => otherBanks.push(Object.assign({}, val)))

  // reference: https://www.tutorialspoint.com/insert-a-number-into-a-sorted-array-of-numbers-javascript
  const findIndex = (arr, val) => {
    let low = 0,
      high = arr.length
    while (low < high) {
      let mid = (low + high) >>> 1
      if (arr[mid]["id"] < val) {
        low = mid + 1
      } else {
        high = mid
      }
    }
    return low
  }

  let bank = null
  store.getState().main.provider.forEach(b => {
    if (b.id === props.location.state.jsonInput.providerId) {
      bank = b
    }
  })
  bank = (({ id, name, big4 }) => ({ id, name, big4 }))(bank)

  let toRemove = null
  otherBanks.forEach(b => {
    if (b.id === props.location.state.jsonInput.providerId) {
      toRemove = b
    }
  })
  if (toRemove !== null) {
    const bankIndex = otherBanks.indexOf(toRemove)
    otherBanks.splice(bankIndex, 1)
  }

  let inMainBank = false
  mainBanks.forEach(b => {
    if (b.id === props.location.state.jsonInput.providerId) {
      inMainBank = true
    }
  })

  if (!inMainBank) {
    const index = findIndex(mainBanks, bank["id"])
    mainBanks.splice(index, 0, bank)
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="survey" className="survey-header">
          Select your banks (up to 3)
        </Typography.H3>
        <ProgressBar progress={progress} />
        <div style={{ textAlign: "center", fontFamily: "Arboria-Bold, sans-serif", color: "#E7527A", fontSize: "18px", marginTop: "-10px" }}>get special offers</div>
        <div style={{ textAlign: "center", fontFamily: "Arboria-Bold, sans-serif", color: "#E7527A", fontSize: "18px", marginBottom: "35px" }}>for existing customer</div>
        <ProvidersListView isSelectYourBanks={true} otherBanks={otherBanks} selectedProvider={props.location.state.jsonInput.providerId} list={mainBanks} didSelectProviderCallback={null} didSelectProviders={didSelectProviders} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    banks: state.main.banks,
    surveyId: state.survey.surveyId,
  }
}

export default connect(mapStateToProps)(SelectBanks)
