import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import Disclaimer from "../disclaimer"
import ProductView from "../product-view"
import ProgressBar from "../progress-bar"
import SGSlider from "../slider"
import { providerImage } from "../utils"
import * as styles from "./style.module.scss"

export default function MyCurrentDeal(props) {
  const store = useStore()

  const jsonResponse = props.location.state.responseJson

  const progress = props.location.state.progress

  const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false)

  const productAF = jsonResponse.totalAfPrimary
  const productPR = jsonResponse.purchaseRate

  let isCustomPricingMode = props.location.state.isCustomPricingMode || false
  const [customAnnualFees, setCustomAnnualFees] = useState(store.customAnnualFees != null ? store.customAnnualFees : productAF)
  const [customPurchaseRate, setCustomPurchaseRate] = useState(store.customPurchaseRate != null ? store.customPurchaseRate : productPR)

  //console.log("jsonResponse:", jsonResponse)
  const provider = store.getState().main.provider.filter(item => item.id === jsonResponse.providerId)[0]
  const product = store.getState().main.products.filter(item => item.id === jsonResponse.productId)[0]
  // //console.log("provider:", provider)
  // //console.log("product:", product)
  const isCustomPricing = customPurchaseRate !== productPR || customAnnualFees !== productAF

  const prevPath = props.location.state.prevPath

  const myPricingDifferent = () => {
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_PRICING_DIFFERENT_SELECTED, { ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })

    navigate("/credit-cards/your-current-pricing", {
      state: {
        ...props.location.state,
        isCustomPricingMode: true,
        progress: progress,
      },
      replace: prevPath === "/select-card-balance" ? false : true,
    })
  }

  const changePricing = () => {
    let jsonInput = props.location.state.jsonInput
    jsonInput.customAnnualFees = customAnnualFees
    jsonInput.customPurchaseRate = customPurchaseRate
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_PRICING_CHANGED, {
      ...(productAF !== customAnnualFees && { customAnnualFees: customAnnualFees }),
      ...(productPR !== customPurchaseRate && { customPurchaseRate: customPurchaseRate }),
      ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }),
    })

    if (productAF === customAnnualFees) {
      const { customAnnualFees, ...rest } = jsonInput
      jsonInput = rest
    }

    if (productPR === customPurchaseRate) {
      const { customPurchaseRate, ...rest } = jsonInput
      jsonInput = rest
    }

    //console.log('jsonInput:', jsonInput, productAF, productPR)

    API.myCurrentDeal(jsonInput, responseJson => {
      navigate("/credit-cards/your-current-deal", {
        state: {
          responseJson,
          jsonInput,
          progress: progress,
          prevPath: props.path,
        },
        replace: prevPath === "/select-card-balance" ? false : true,
      })
    })
  }

  const currentProductView = () => (
    <div>
      <Typography.H3 comp="survey" className="survey-header">
        Your current deal
      </Typography.H3>

      <ProgressBar progress={progress} />

      <div style={{ display: "flex", height: "66px", width: "100%", marginBottom: "30px" }}>
        <div style={{ height: "100%", width: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <LazyLoadImage src={providerImage(jsonResponse.providerId)} style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "66%" }} alt="" />
        </div>
        <div style={{ height: "100%", width: "34%", borderRight: "0.5px solid #D2D2D2", borderLeft: "0.5px solid #D2D2D2" }}>
          <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>estimated cost</div>
          <div style={{ textAlign: "center", height: "29px", display: "block", lineHeight: "29px", fontSize: "24px", color: "#202020", fontFamily: "Arboria-Bold, sans-serif" }}>
            {jsonResponse.customerCost < 0 ? "-" : null}${Math.abs(Math.floor(jsonResponse.customerCost)).toLocaleString()}
            {props.location.state.jsonInput.monthlyRepayment === 1 ? "*" : null}
          </div>
          <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>over 3 years</div>
        </div>
        <div style={{ height: "100%", width: "33%" }}>
          {jsonResponse.rewardProgramId === 0 ? (
            <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ textAlign: "center", lineHeight: "20px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>none</div>
            </div>
          ) : (
            <div>
              <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>{jsonResponse.rewardsCategory === "general_rewards" || jsonResponse.rewardsCategory === "no_rewards" ? "estimated rewards" : "estimated points"}</div>
              <div style={{ textAlign: "center", height: "29px", display: "block", lineHeight: "29px", fontSize: "24px", color: "#202020", fontFamily: "Arboria-Bold, sans-serif" }}>{jsonResponse.rewardsCategory === "general_rewards" || jsonResponse.rewardsCategory === "no_rewards" ? "$" + Math.floor(jsonResponse.rewardsValue).toLocaleString() : Math.floor(jsonResponse.totalPoints).toLocaleString()}</div>
              <div style={{ textAlign: "center", height: "16px", display: "block", lineHeight: "16px", fontSize: "12px", color: "#777777", fontFamily: "Arboria-Book, sans-serif" }}>over 3 years</div>
            </div>
          )}
        </div>
      </div>
      <ProductView product={product} />

      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
        <Typography.H6 comp="list-heading" style={{ height: "28px", borderBottom: "0.5px solid #797979" }}>
          {isCustomPricing ? "Your Pricing" : "Standard Pricing"}
        </Typography.H6>
        <div style={{ marginTop: "5px" }} className={styles.cardDetailsContainer}>
          <Typography.P comp={"x-small"}>Total Annual Fee</Typography.P>
          <Typography.P comp={"x-small"}>${customAnnualFees}</Typography.P>
        </div>
        <div className={styles.cardDetailsContainer}>
          <Typography.P comp={"x-small"}>Interest on Purchases</Typography.P>
          <Typography.P comp={"x-small"}>{(customPurchaseRate * 100).toFixed(2)}% p.a.</Typography.P>
        </div>
        <div className={styles.cardDetailsContainer}>
          <Typography.P comp={"x-small"}>Interest Free Period</Typography.P>
          <Typography.P comp={"x-small"}>{jsonResponse.freeDays > 0 ? "up to " + jsonResponse.freeDays + " days" : "None"}</Typography.P>
        </div>
      </div>

      {props.location.state.jsonInput.monthlyRepayment === 1 ? <div style={{ color: "#202020", textAlign: "right", fontSize: "16px" }}>* includes international transactions fees</div> : null}

      <Clickable.Text
        className={styles.nextButton}
        comp={3}
        onClick={() => {
          DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_CURRENT_DEAL_CONFIRMED, {
            productId: jsonResponse.productId,
            providerId: jsonResponse.providerId,
            productCategory: "CREDIT_CARD",
            ...(productAF !== customAnnualFees && { customAnnualFees: customAnnualFees }),
            ...(productPR !== customPurchaseRate && { customPurchaseRate: customPurchaseRate }),
          })

          if (productAF !== customAnnualFees) props.location.state.jsonInput.customAnnualFees = customAnnualFees
          if (productPR !== customPurchaseRate) props.location.state.jsonInput.customPurchaseRate = customPurchaseRate
          props.location.state.jsonInput.providerId = jsonResponse.providerId
          // navigate("/credit-cards/select-banks-redirect", {
          //   state: {
          //     jsonInput: props.location.state.jsonInput,
          //     progress: progress + 1,
          //   },
          // })
          navigate("/credit-cards/select-banks", {
            state: {
              jsonInput: props.location.state.jsonInput,
              progress: progress + 1,
            },
          })
          props.location.state.jsonInput.providers = []
          props.location.state.jsonInput.flags = store.getState().survey.flags
          // navigate("/credit-cards/searching", {
          //   state: {
          //     jsonInput: props.location.state.jsonInput,
          //     // progress: progress + 1,
          //   },
          // })
          // const providers = []
          // store.getState().survey.selectedProviders.forEach(p => {
          //   providers.push(p.id)
          // })
          // props.location.state.jsonInput.providers = providers
          // props.location.state.jsonInput.flags = store.getState().survey.flags
          // API.getOffers(props.location.state.jsonInput, responseJson => {
          //   navigate("/credit-cards/offers", {
          //     state: {
          //       responseJson,
          //       jsonInput: props.location.state.jsonInput,
          //     },
          //   })
          // })
        }}
      >
        {/* Continue */}
        Continue
      </Clickable.Text>

      {/* <Clickable.Text secondary comp={3} className="survey-button" onClick={() => setIsCustomPricingMode(true)}> */}
      <Clickable.Text style={{ marginTop: "20px" }} secondary comp={3} className="survey-button" onClick={() => myPricingDifferent()}>
        My pricing is different
      </Clickable.Text>

      <Disclaimer isDisclaimerOpen={disclaimerIsOpen} />
    </div>
  )

  const customPricingView = () => (
    <div>
      <Typography.H3 comp="survey" className="survey-header">
        Select your pricing
      </Typography.H3>

      <ProgressBar progress={progress} />

      <ProductView product={product} />

      {productAF ? (
        <div>
          <div style={{ marginTop: "54px" }} className={styles.cardDetailsContainer}>
            <Typography.H6>Total Annual Fees</Typography.H6>
            <Typography.H6 fontColor="#00AFC5">${customAnnualFees}</Typography.H6>
          </div>
          <Typography.P style={{ marginTop: "15px" }}>
            Standard annual fees:
            <br />
            {jsonResponse.afFreq === "P1M" ? "12x " : ""} ${jsonResponse.accountFee} Account Fee {jsonResponse.rewardsFee > 0 ? " + $" + jsonResponse.rewardsFee + " Reward Program Fee" : ""}
          </Typography.P>
          <SGSlider
            value={customAnnualFees}
            onChange={(event, newValue) => {
              setCustomAnnualFees(newValue)
              store.customAnnualFees = newValue
            }}
            step={1}
            min={0}
            max={jsonResponse.totalAfPrimary}
            aria-labelledby="continuous-slider"
          />
        </div>
      ) : null}
      {productPR ? (
        <div>
          <div style={{ marginTop: "54px" }} className={styles.cardDetailsContainer}>
            <Typography.H6>Purchase Rate</Typography.H6>
            <Typography.H6 fontColor="#00AFC5">{(customPurchaseRate * 100).toFixed(2)}%</Typography.H6>
          </div>
          <Typography.P style={{ marginTop: "15px" }}>
            Ongoing, not introductory
            <br />
            {jsonResponse.freeDays > 0 ? "Up to " + jsonResponse.freeDays + " days interest free" : "No interest free days"}
          </Typography.P>
          <SGSlider
            value={customPurchaseRate}
            onChange={(event, newValue) => {
              setCustomPurchaseRate(newValue)
              store.customPurchaseRate = newValue
            }}
            step={0.0025}
            min={0}
            max={productPR}
            aria-labelledby="continuous-slider"
          />
        </div>
      ) : null}

      <Clickable.Text className={styles.nextButton} onClick={() => changePricing()}>
        Confirm pricing
      </Clickable.Text>
    </div>
  )

  return (
    <div className={"page-container"}>
      <div className="survey-container">{isCustomPricingMode ? customPricingView() : currentProductView()}</div>
    </div>
  )
}
