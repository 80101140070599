// import  store from  "state/store"
import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import React from "react"
import SGTextfield from "../../textfield"
// import  { navigate } from  "gatsby"
import * as styles from "./style.module.scss"

const RegisterMobile = props => {
  // const [email, setEmail] = useState("")
  // const [isEmailValid, setIsEmailValid] = useState(false)

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="survey" className="estimate-header-label">
          Personalised Offer
        </Typography.H3>
        <Typography.P className="estimate-sub-header-label">This personalised offer is not publicly available. Please register to access.</Typography.P>
        <div>
          <SGTextfield className={styles.tf} id="outlined-basic" label="mobile number" />
        </div>

        <Clickable.Text comp={3} className="survey-button">
          Continue
        </Clickable.Text>

        <Clickable.Text secondary comp={3} className={styles.bt2}>
          Back
        </Clickable.Text>
      </div>
    </div>
  )
}

export default RegisterMobile
