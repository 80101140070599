import { Router } from "@reach/router"
import BanksList from "components/content/banks-list"
import Layout from "components/layout"
import NotFound from "components/not-found"
import BenefitsSummary from "components/survey/benefits-summary"
import Instructions from "components/survey/instructions"
import MyCurrentDeal from "components/survey/my-current-deal"
import OfferDetails from "components/survey/offer-details"
import Offers from "components/survey/offers"
import ProductTransfer from "components/survey/product-transfer"
import Redirect from "components/survey/redirect"
import RegisterMobile from "components/survey/register-mobile"
import Searching from "components/survey/searching"
import SelectBanks from "components/survey/select-banks"
// import SelectBanksRedirect from  "components/survey/select-banks-redirect"
// import  Settings from  "components/survey/settings"
import SelectCard from "components/survey/select-card"
import React from "react"

const SurveyBase = ({ component: Component, ...rest }) => {
  // const sendChangesIfNeeded = () => {
  //   if (store.getState().survey.uid) {
  //     API.surveyUpdate(
  //       store.getState().survey.uid,
  //       store.getState().survey.cards,
  //       responseJson => {}
  //     )
  //     //console.log("001 sendChangesIfNeeded true")
  //     return true
  //   }
  //   //console.log("001 sendChangesIfNeeded false")
  //   return false
  // }

  // useEffect(() => {
  //   //console.log("001 Component did mount (it runs only once)")
  //   return () => {
  //     //console.log("001 Component will unmount")
  //     sendChangesIfNeeded()
  //     store.dispatch(clearSurvey())
  //   }
  // }, [])

  // useBeforeunload(() => {
  //   if (sendChangesIfNeeded()) return "nir"
  // })
  return <Component {...rest} />
}

export default function Survey(props) {
  // useEffect(() => {
  //   loadMain()
  //   return () => { }
  // }, [])

  return (
    <Layout title="Credit Card Comparison | Stay or Go" description="Compare credit card offers from all the major banks and popular brands." image="/video/StayOrGo_Animation_01.jpf" urlSlug="credit-cards">
      <Router basepath="/credit-cards" primary={false}>
        <SurveyBase path="/" component={BanksList} />
        <SurveyBase path="/instructions" component={Instructions} />
        <SurveyBase path="/select-card-provider" component={SelectCard} />
        <SurveyBase path="/select-card-provider/add-missing-provider" component={SelectCard} />
        <SurveyBase path="/select-card-provider/missing-provider-registration" component={SelectCard} />
        <SurveyBase path="/select-card-provider/missing-provider-thank-you" component={SelectCard} />
        <SurveyBase path="/share-account-information" component={SelectCard} />
        <SurveyBase path="/select-card-rewards" component={SelectCard} />
        <SurveyBase path="/select-card-product" component={SelectCard} />
        <SurveyBase path="/select-card-product/add-missing-product" component={SelectCard} />
        <SurveyBase path="/select-card-product/missing-product-registration" component={SelectCard} />
        <SurveyBase path="/select-card-product/missing-product-thank-you" component={SelectCard} />
        <SurveyBase path="/select-card-repayment" component={SelectCard} />
        <SurveyBase path="/select-card-spend" component={SelectCard} />
        <SurveyBase path="/select-card-balance" component={SelectCard} />
        <SurveyBase path="/searching" component={Searching} />
        <SurveyBase path="/your-current-deal" component={MyCurrentDeal} />
        <SurveyBase path="/your-current-pricing" component={MyCurrentDeal} />
        <SurveyBase path="/benefits-summary" component={BenefitsSummary} />
        <SurveyBase path="/benefits-summary-registration" component={BenefitsSummary} />
        <SurveyBase path="/offer-details" component={OfferDetails} />
        <SurveyBase path="/offers" component={Offers} />
        <SurveyBase path="/register-mobile" component={RegisterMobile} />
        <SurveyBase path="/select-banks" component={SelectBanks} />
        {/* <SurveyBase path="/select-banks-redirect" component={SelectBanksRedirect} /> */}
        <SurveyBase path="/redirect" component={Redirect} />
        <SurveyBase path="/product-transfer" component={ProductTransfer} />
        <SurveyBase path="/product-transfer/thank-you" component={ProductTransfer} />

        <NotFound default />
      </Router>
    </Layout>
  )
}
