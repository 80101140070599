// import  MobileStepper from  "@mui/material/MobileStepper"
import * as List from "components/list"
// import  * as Clickable from  "components/clickable"
import { Typography } from "components/typography"
import React, { useState } from "react"
import { connect, useStore } from "react-redux"

export const CardUsageControllerView = props => {
  const monthlySpend = props.monthlySpend || []
  const monthlyRepayment = props.monthlyRepayment || []
  const balanceRemaining = props.balanceRemaining || []

  const store = useStore()
  const activeStep = props.activeStep

  const getAnswer = () => {
  //  console.log("Store", store)
    if (activeStep === 0) return store.cardUsage.monthlyRepayment
    if (activeStep === 1) return store.cardUsage.monthlySpend
    return store.cardUsage.balanceRemaining
  }

  const [answer, setAnswer] = useState(getAnswer() || -1)

  const titles = ["Typical monthly repayment", "Typical monthly spend", "Current Balance"]
  const helpInfo = ['Select the response that best reflects how often you pay off your credit card’s balance in full. If your card is only being used to pay down a balance, then select "Paying off a balance (no spend)".', "Select the range that best reflects how much you typically spend on your card each month.", "Select the range that best reflects the balance (amount owed) on your card, including any interest free amounts."]
  const lists = [monthlyRepayment, monthlySpend, balanceRemaining]
  const title = titles[activeStep]
  const list = lists[activeStep]

  const storeAnswer = value => {
    if (activeStep === 0) {
      if (store.cardUsage.monthlyRepayment === 5) store.cardUsage.monthlySpend = -1
      store.cardUsage.monthlyRepayment = value
    } else if (activeStep === 1) store.cardUsage.monthlySpend = value
    else store.cardUsage.balanceRemaining = value
  }

  const onItemSelected = item => {
    setAnswer(item.id)
    setTimeout(() => {
      storeAnswer(item.id)
      props.callback(item.id)
    }, 50)
  }

  return (
    <div>
      <div>
        <List.ListHeader title={title}>
          <Typography.P comp="survey-help-question">How should I answer this?</Typography.P>
          <Typography.P>{helpInfo[activeStep]}</Typography.P>
          <br />
          <Typography.P comp="survey-help-question">Why are we asking this?</Typography.P>
          <Typography.P>To find you a better deal, we need to understand a few things about how you use your card.</Typography.P>
        </List.ListHeader>
        {list.map((item, i) => (
          <List.ListItem key={i} text={item.value} selected={answer === item.id} onClick={() => onItemSelected(item)} />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  monthlySpend: state.main.monthlySpend,
  monthlyRepayment: state.main.monthlyRepayment,
  balanceRemaining: state.main.balanceRemaining,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CardUsageControllerView)
