import * as Clickable from "components/clickable"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ProductView from "../product-view"
import * as styles from "./style.module.scss"

const ProductsListView = props => {
  const { selected, callback } = props
  let list = props.list.filter(x => x.inSurvey === true || x.id === 0)

  if (selected !== null) list = [selected]

  return (
    <div className={styles.root}>
      {list.map((item, i) => {
        return (
          <Clickable.View
            key={i}
            rootStyle={
              selected
                ? {
                  paddingBottom: "50px",
                }
                : {
                  paddingTop: "20px",
                  paddingBottom: "40px",
                  borderBottom: "0.5px solid #eeeeee",
                }
            }
            noFlex={true}
            disabled={selected !== null}
            onClick={() => {
              if (selected) return
              if (item.id === 0) {
                navigate("/credit-cards/select-card-product/add-missing-product", {
                  state: {
                    addMissingCard: true,
                    cardProvider: item.cardProvider,
                    rewardProgram: item.rewardProgram,
                    progress: 2,
                  },
                })
                return
              }
              callback(item)
            }}
          >
            <ProductView cardImageSizeLarge={true} product={item} showAll={selected !== null ? false : true} />
          </Clickable.View>
        )
      })}
    </div>
  )
}

ProductsListView.propTypes = {
  selected: PropTypes.object,
  list: PropTypes.array.isRequired,
}

export default ProductsListView
