import Slider from "@mui/material/Slider"
import React from "react"

const SGSlider = props => (
  <Slider
    sx={{
      "&.MuiSlider-root": {
        color: "#797979",
        width: "calc(100% - 32px)",
        marginLeft: "16px",
        marginRight: "0%",
        marginTop: "23px",
        borderRadius: "0px",
      },
      "& .MuiSlider-thumb": {
        color: "#2cb9c6",
        height: "30px !important",
        width: "30px !important",
        marginTop: "-1.5px",
        marginLeft: "0.5px",
      },
      "& .MuiSlider-rail": {
        height: "4px",
        backgroundColor: "#e8e8e8",
        opacity: "1",
        marginLeft: "-15px",
        width: "calc(100% + 30px)",
      },
      "& .MuiSlider-track": {
        opacity: "1",
        height: "4px",
        borderWidth: "0px",
        backgroundColor: "#e8e8e8",
      },
    }}
    {...props}
  />
)

export default SGSlider
