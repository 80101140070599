import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "components/typography"

import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import ReactPlayer from "react-player"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { CreditCardTopPicks as TopPicks } from "./top-picks"
import Articles from "components/content/articles"
import { ListHeaderProvider } from "components/content/list-header"
import CreditCardContent from "../../models/content-models/CreditCardContent"
import Faq from "./faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "./map-to-div"
import AppManager from "services/app-manager"
import ProductCategoryPageTopSection from "./product-category-page-top-section"
import { SectionTextDivider, SectionTextSubdivider } from "./product-category-page-section-header"

export default function BanksListView(props: any) {
  const isWide = useMediaQuery("(min-width:768px)")
  const chunkSize = isWide ? 4 : 2

  const dateUpdated = AppManager.getInstance().getProductCategoryDateUpdated("cc")
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated ? dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4) : ""

  const providers = Object.values(CreditCardContent.ccData.providers)
  const rewards = Object.values(CreditCardContent.ccData.rewards)
  const features = [
    { name: "Low Rate", urlSlug: "low-rate-credit-cards", img: "/images/low_rate.png" },
    { name: "Interest Free", urlSlug: "interest-free-credit-cards", img: "/images/interest_free.png" },
    { name: "Balance Transfer", urlSlug: "balance-transfer-credit-cards", img: "/images/balance_transfer.png" },
    { name: "0% Purchase", urlSlug: "zero-purchase-credit-cards", img: "/images/0_purchase.png" },
    { name: "No Annual Fee", urlSlug: "no-annual-fee-credit-cards", img: "/images/no_annual_fee.png" },
    { name: "Cash Back", urlSlug: "cash-back-credit-cards", img: "/images/cash_back.png" },
    { name: "No Foreign Transaction Fee", urlSlug: "no-foreign-transaction-fee-credit-cards", img: "/images/0_international_fees.png" },
    { name: "Travel Insurance", urlSlug: "travel-insurance-credit-cards", img: "/images/travel_insurance.png" },
  ]
  const mainRewards = rewards ? rewards.filter(x => x.popular === 1) : []
  const otherRewards = rewards ? rewards.filter(x => x.popular) : []
  const mainItems = providers ? providers.filter(x => x.big4 === true && (!props.indexPage || x.popular) && Object.values(x.products).length > 0 && x.name !== "My provider is not here").sort((a, b) => (a.id > b.id ? 1 : -1)) : []
  const otherItems = providers ? providers.filter(x => x.big4 === false && (!props.indexPage || x.popular) && Object.values(x.products).length > 0 && x.name !== "My provider is not here").sort((a, b) => (a.id > b.id ? 1 : -1)) : []

  const majors = providers ? chunkifyArray(mainItems, chunkSize) : []
  const popular = providers ? chunkifyArray(otherItems, chunkSize) : []
  const popularRewardLines = rewards ? chunkifyArray(mainRewards, chunkSize) : []
  const otherRewardLines = rewards ? chunkifyArray(otherRewards, chunkSize) : []
  const featureLines = features ? chunkifyArray(features, chunkSize) : []

  const queryResult = useStaticQuery(graphql`
    {
      allContentfulContentQa {
        nodes {
          slug
          qa {
            qa
          }
        }
      }
    }
  `)
  const qa = queryResult.allContentfulContentQa.nodes.find((x: any) => x.slug === "/credit-cards")

  const landingPageTextView = (num: number) => {
    return (
      <div>
        <div>
          <Typography.H2>{num === 0 ? "Compare leading credit card brands" : "Get a better deal from your bank"}</Typography.H2>
          <Typography.P comp="large">{num === 0 ? "Compare credit card offers from all the major banks and popular brands." : "Instantly access a better credit card deal without needing to change banks or reapply for credit."}</Typography.P>
        </div>
      </div>
    )
  }

  const getVideo = (num: number) => {
    const videos = [
      {
        url: "/video/StayOrGo_Animation_03.mp4",
        placeholder: "/video/StayOrGo_Animation_03.jpf",
        alt: "Explore leading brands and credit cards",
      },
      {
        url: "/video/StayOrGo_Animation_02.mp4",
        placeholder: "/video/StayOrGo_Animation_02.jpf",
        alt: "Get a better credit card deal from your bank",
      },
    ]

    return (
      <div>
        <ReactPlayer playing={true} url={videos[num].url} volume={0} loop={true} playsinline={true} width="100%" height="100%" />
        {/* <img className="img" src={placeholder} alt={alt} /> */}
      </div>
    )
  }

  const renderTextVideo = (num: number) => {
    return (
      <div>
        <div className="show-for-desktop">
          {num === 0 ? (
            <div className="container-horizontal container--space-between">
              <div className="landing-page-section-left">{landingPageTextView(num)}</div>
              <div className="landing-page-section-right">{getVideo(num)}</div>
            </div>
          ) : (
            <div className="container-horizontal container--space-between">
              <div className="landing-page-section-left">{getVideo(num)}</div>
              <div className="landing-page-section-right">{landingPageTextView(num)}</div>
            </div>
          )}
        </div>
        <div className="show-for-mobile">
          <div className="landing-page-section-left container-bottom">{landingPageTextView(num)}</div>
          <div className="landing-page-section-right container-center">
            <div className=" landing-page-video-container-mobile container-center">{getVideo(num)}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {!props.indexPage ? (
        <ProductCategoryPageTopSection
          productCategory="cc"
          dateString={dateString}
          pageHeading={`Compare best credit cards`}
          categoryDescriptionHeading="Get a better deal from your bank"
          categoryDescription="Instantly access a better credit card deal without needing to change banks or reapply for credit."
          imageOrVideoComponent={getVideo(1)}
          surveyUrlSlug="/credit-cards/instructions/"
          surveyButtonOnClick={() => {
            DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "CREDIT-CARDS" })
          }}
        />
      ) : (
        <SectionTextDivider heading={`${props.indexPage ? "Compare" : "Explore"} credit cards from leading brands`} text="Compare credit card offers from all the major banks and popular brands." style={{ marginBottom: "60px" }} />
      )}

      {!props.indexPage ? (
        <div>
          <div style={{ marginTop: "150px", marginBottom: "130px" }}>
            <SectionTextDivider heading="Compare credit cards by feature" text="Compare credit cards that offer popular rewards programs and features, including Low Rate, No Annual Fee, and 0% Balance Transfer." />
            <SectionTextSubdivider subheading="Popular features" />
            <MapToDiv.Features features={featureLines} />
            <SectionTextSubdivider subheading="Popular rewards programs" />
            <MapToDiv.Rewards rewards={popularRewardLines} />
          </div>
          <TopPicks />
          <div id="providers" />
          {renderTextVideo(0)}
        </div>
      ) : null}

      <SectionTextSubdivider subheading="Majors" text="Big banks with extensive nationwide networks that provide a comprehensive range of services" />
      <MapToDiv.Banks banks={majors} productCategory="credit-cards" />
      <SectionTextSubdivider subheading="Challengers" text="Regional and international banking institutions that offer financial services" />
      <MapToDiv.Banks banks={popular} productCategory="credit-cards" />
      <SectionTextSubdivider subheading="Rewards Programs" text="Earn and redeem travel rewards, loyalty points, along with other perks and benefits" />
      <MapToDiv.Rewards rewards={otherRewardLines} />

      {/* {props.indexPage ? (
        <Clickable.Text
          comp={1}
          component={Link}
          to={"/credit-cards/"}
          className="landing-page-button"
          style={{ marginTop: "100px" }}
          onClick={() => {
            DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_CATEGORY_SELECTED, { category: "/credit-cards" })
          }}
        >
          See all
        </Clickable.Text>
      ) : null} */}
      {!props.indexPage && qa ? (
        <div>
          <Articles />
          <div style={{ marginTop: "100px" }}>
            <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
            {/* <div>
              {compileSchemaOrgQa(qa.qa.qa)}
              <Markdown md={qa.qa.qa} isArticle={true} />
            </div> */}
            <Faq faqContent={qa.qa.qa} />
          </div>
        </div>
      ) : null}
    </div>
  )
}
