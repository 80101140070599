import * as Clickable from "components/clickable"
import * as List from "components/list"
import { Typography } from "components/typography"
import PropTypes from "prop-types"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { rewardProgramImage } from "../utils"
import * as styles from "./style.module.scss"

export const RewardProgramsListView = props => {
  const { selected, callback } = props
  let { list } = props
  if (selected !== null) list = [selected]

  return (
    <div>
      {selected === null && (
        <List.ListHeader title="Reward points">
          <Typography.P>Select the type of reward points that your credit card earns, or otherwise “No Reward Points”. If you’re unsure, then you can explore the cards within each option by using the back navigation {"'<'"} to return to this list.</Typography.P>
        </List.ListHeader>
      )}

      {list.map((item, i) => {
        return (
          <Clickable.View
            key={i}
            rootStyle={
              selected
                ? {
                    height: "50px",
                    borderBottom: "0.5px solid #eeeeee",
                  }
                : {
                    height: "60px",
                    borderBottom: "0.5px solid #eeeeee",
                  }
            }
            disabled={selected !== null}
            onClick={() => {
              if (selected) return
              callback(item)
            }}
          >
            <LazyLoadImage src={rewardProgramImage(item.id)} style={{width: "56px", height: "38px"}} alt="" />
            <div className={styles.listItemText}>
              <Typography.H6 comp="list-item">{item.value}</Typography.H6>
            </div>
          </Clickable.View>
        )
      })}
    </div>
  )
}

RewardProgramsListView.propTypes = {
  selected: PropTypes.object,
  list: PropTypes.array.isRequired,
}

export default RewardProgramsListView
