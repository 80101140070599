import Product from "./Product"

export default abstract class Feature {
  public readonly MODEL_CATEGORY: string = "feature"

  public readonly name: string
  public readonly nameFormatted: string
  public readonly heading: string
  public readonly urlSlug: string
  public readonly defaultSort: string
  public products: { [id: string]: Product }

  // = CUSTOM FUNCTIONS =
  public addProduct(product: Product) {
    this.products[product.id] = product
  }

  // Sort products, with GRANDFATHERED and PAUSED products at the bottom
  protected compare(a: Product, b: Product): number {
    const order: { [id: string]: number } = { Y: 1, GRANDFATHERED: 2, PAUSED: 3 }
    const orderA = order[a.forSale]
    const orderB = order[b.forSale]
    const hasOfferA = Object.values(a.offers).some(o => o.active === "Y" && o.landingPageUrl)
    const hasOfferB = Object.values(b.offers).some(o => o.active === "Y" && o.landingPageUrl)
    
    if (orderA > orderB) {
      return 1
    } else if (orderB > orderA) {
      return -1
    }

    if (hasOfferA && !hasOfferB) {
      return -1
    } else if (hasOfferB && !hasOfferA) {
      return 1
    }

    if (a.participant > 0 && b.participant === 0) {
      return -1
    } else if (b.participant > 0 && a.participant === 0) {
      return 1
    }

    return Number(a.id) - Number(b.id)
  }

  // TODO: remove this?
  public abstract getFilterAmounts(): { [id: string]: number }
  public abstract getTitle(): string
  public abstract getAutoDescription(): string

  protected constructor(d: any) {
    this.name = d.name
    this.nameFormatted = d.nameFormatted
    this.heading = d.heading
    this.urlSlug = d.urlSlug
    this.defaultSort = d.defaultSort
    this.products = d.products
  }
}

