import Feature from "./Feature"
import Provider from "./Provider"
import Reward from "./Reward"

// Stores the currently-being-typechecked object for error messages.
export default abstract class Content {
  public readonly MODEL_CATEGORY: string = "content"
  
  public readonly providers: { [id: string]: Provider }
  public readonly rewards: { [id: string]: Reward }
  public readonly popularFeatures: { [id: string]: Feature }
  public readonly dateUpdated: string

  // = CUSTOM FUNCTIONS =
  public getProviderById(id: number): Provider {
    return this.providers[id.toString()]
  }
  public getRewardById(id: number): Reward {
    return this.rewards[id.toString()]
  }

  // = AUTOMATED FUNCTIONS =
  
  protected constructor(d: any) {
    this.providers = d.providers
    this.rewards = d.rewards
    this.popularFeatures = d.popularFeatures
    this.dateUpdated = d.date_updated
  }
}
